import PropTypes from 'prop-types';
import React, { Component } from 'react';

import GalleryContent from '../GalleryContent/GalleryContent.js';
import ImageContent from '../ImageContent/ImageContent.js';
import TextContent from '../TextContent/TextContent.js';

export default class ContentStructure extends Component {
  render() {
    const {
      contentComponent: {
        columns,
        content,
        delay,
        full,
        hasAnimation,
        items,
        rowPaddingBottom,
        rowPaddingTop,
        textAlign,
        type,
      },
    } = this.props;
    return (
      <div>
        {type === 'text' && (
          <TextContent
            content={content}
            hasAnimation={hasAnimation}
            textAlign={textAlign}
            full={full}
            rowPaddingTop={rowPaddingTop}
            rowPaddingBottom={rowPaddingBottom}
            delay={delay}
          />
        )}

        {type === 'images' && (
          <ImageContent
            columns={columns}
            items={items}
            hasAnimation={hasAnimation}
            textAlign={textAlign}
            full={full}
            rowPaddingTop={rowPaddingTop}
            rowPaddingBottom={rowPaddingBottom}
            delay={delay}
          />
        )}

        {type === 'gallery' && (
          <GalleryContent
            items={items}
            hasAnimation={hasAnimation}
            textAlign={textAlign}
            full={full}
            rowPaddingTop={rowPaddingTop}
            rowPaddingBottom={rowPaddingBottom}
            delay={delay}
          />
        )}
      </div>
    );
  }
}

ContentStructure.propTypes = {
  contentComponent: PropTypes.shape({
    columns: PropTypes.any,
    content: PropTypes.any,
    delay: PropTypes.any,
    full: PropTypes.any,
    hasAnimation: PropTypes.any,
    items: PropTypes.any,
    rowPaddingBottom: PropTypes.any,
    rowPaddingTop: PropTypes.any,
    textAlign: PropTypes.any,
    type: PropTypes.string,
  }),
};
