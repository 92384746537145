import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import { Waypoint } from 'react-waypoint';
import LinkPageTransition from '../LinkPageTransition';

class ProjectNav extends Component {
  constructor() {
    super();

    const defaultState = {
      animateIn: false,
    };

    this.state = defaultState;
  }

  render() {
    const { className, nextProject } = this.props;
    const { animateIn } = this.state;
    return (
      <Waypoint
        onEnter={() => {
          this.setState({
            animateIn: true,
          });
        }}
      >
        <div
          id="project-nav"
          className={cx({
            'has-animation': true,
            'animate-in': animateIn,
          })}
          data-delay="100"
        >
          <LinkPageTransition
            className={[`ajax-link project-next`]
              .concat(className || [])
              .join(' ')}
            data-type="page-transition"
            to={nextProject.frontmatter.path}
          >
            <div className="nav-project-title">
              {nextProject.frontmatter.title}
            </div>
            <div className="nav-title">next</div>
          </LinkPageTransition>
        </div>
      </Waypoint>
    );
  }
}

export default ProjectNav;

ProjectNav.propTypes = {
  className: PropTypes.string,
  nextProject: PropTypes.object,
};
