import React, { Component } from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import cx from 'classnames';

import AppStore from '../../store/AppStore';

import './styles.scss';

class ImageContent extends Component {
  constructor() {
    super();

    this.state = {
      animateIn: false,
    };
  }

  render() {
    const {
      full,
      hasAnimation,
      rowPaddingBottom,
      rowPaddingTop,
      textAlign,
      delay,
      items,
      columns,
    } = this.props;

    const { animateIn } = this.state;

    const getImage = (key) => {
      if (AppStore.config.images[key] !== undefined) {
        return AppStore.config.images[key];
      }
      console.error(`no image with key "${key}" found`);
      return false;
    };

    return (
      <Waypoint
        onEnter={() => {
          this.setState({
            animateIn: true,
          });
        }}
      >
        <div
          className={cx({
            vc_row: true,
            full,
            small: !full,
            'animate-in': animateIn,
            'has-animation': hasAnimation,
            row_padding_top: rowPaddingTop,
            row_padding_bottom: rowPaddingBottom,
            [`text-align-${textAlign}`]: textAlign !== null,
          })}
          data-delay={delay}
        >
          <div className="container">
            {items.length === 0 && <h1>no items provided</h1>}
            {items.map((image, index) => (
              <div
                key={`imgcontent_${index}`}
                className={cx({
                  one_half: columns === 2,
                  one_third: columns === 3,
                  'has-animation': false,
                  last: (index + 1) % columns === 0,
                })}
                data-delay="100"
              >
                {getImage(image.image) && (
                  <a href={getImage(image.image).src} className="image-link">
                    <Img fluid={getImage(image.image)} />
                  </a>
                )}

                {/* <hr />
                <hr />
                <hr />
                <hr /> */}
              </div>
            ))}
          </div>
        </div>
      </Waypoint>
    );
  }
}

ImageContent.propTypes = {
  columns: PropTypes.number,
  delay: PropTypes.any,
  full: PropTypes.bool,
  hasAnimation: PropTypes.bool,
  items: PropTypes.array,
  rowPaddingBottom: PropTypes.bool,
  rowPaddingTop: PropTypes.bool,
  textAlign: PropTypes.string,
};

ImageContent.defaultProps = {
  columns: 2,
  items: [],
  hasAnimation: true,
  textAlign: 'center',
  full: true,
  rowPaddingBottom: true,
  rowPaddingTop: true,
};

export default ImageContent;
