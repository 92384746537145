import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import * as AppActions from '../actions/AppActions';
import ContentStructure from '../components/ContentStructure/ContentStructure.js';
import ProjectNav from '../components/ProjectNav/ProjectNav.js';
import TextContent from '../components/TextContent/TextContent.js';
import Hero from '../components/Hero/Hero.js';
import Layout from '../layouts/index.js';

const Template = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
  pageContext: { next },
  location,
  ...props
}) => {
  useEffect(() => {
    AppActions.changeColors(frontmatter.colors);

    const timer = setTimeout(() => {
      window.HeroSection();
      window.FirstLoad();
      window.LazyLoad();
      window.FullPage();
      window.MasonryPortfolio();
      window.HideShowHeader();
      window.FooterAppear();
      window.Sliders();
      window.Lightbox();
      window.AppearIteam();
      window.BackToTop();
      window.ContactForm();
      window.ShowMain();
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, 200);
    return () => clearTimeout(timer);
  }, [frontmatter.colors]);

  const isProjectPage = frontmatter.layout === 'project';
  const structure = frontmatter.structure || [];

  return (
    <Layout location={location}>
      <div>
        <Hero />
        <div id="hero-height" />
        <div id="main-content">
          <Helmet
            title={`Pia Flechtner – Styling Hamburg - ${frontmatter.subtitle}`}
            meta={[
              {
                name: 'description',
                content: frontmatter.meta.description,
              },
            ]}
          />

          {html && <TextContent content={html} />}

          {structure.map((contentComponent, index) => (
            <ContentStructure
              key={`structure_${index}`}
              contentComponent={contentComponent}
            />
          ))}

          {next && isProjectPage && <ProjectNav nextProject={next} />}
        </div>
      </div>
    </Layout>
  );
};

export default Template;

Template.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        tags
        title
        subtitle
        meta {
          description
        }
        colors {
          highlight
          background
        }
        layout
        structure {
          type
          content
          hasAnimation
          textAlign
          full
          rowPaddingTop
          rowPaddingBottom
          delay
          columns
          items {
            image
          }
        }
      }
    }
  }
`;
