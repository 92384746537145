import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import Img from 'gatsby-image';

import AppStore from '../../store/AppStore';

class GalleryContent extends Component {
  constructor() {
    super();

    this.state = {
      animateIn: false,
    };
  }

  render() {
    const {
      full,
      hasAnimation,
      rowPaddingTop,
      rowPaddingBottom,
      textAlign,
      delay,
      items,
    } = this.props;

    const { animateIn } = this.state;

    return (
      <Waypoint
        onEnter={() => {
          this.setState({
            animateIn: true,
          });
        }}
      >
        <div
          className={cx({
            vc_row: true,
            full,
            small: !full,
            'animate-in': animateIn,
            'has-animation': hasAnimation,
            'row-padding-top': rowPaddingTop,
            'row-padding-bottom': rowPaddingBottom,
            [`text-align-${textAlign}`]: textAlign !== null,
          })}
          data-delay={delay}
        >
          <div className="container">
            <div className="slider">
              {items.map((image, index) => (
                <div className="slide" key={`slide_${index}`}>
                  <a href={image.image} className="image-link">
                    <Img
                      fluid={AppStore.config.images[image.image]}
                      alt="Image Title"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Waypoint>
    );
  }
}

GalleryContent.propTypes = {
  hasAnimation: PropTypes.bool,
  textAlign: PropTypes.string,
  full: PropTypes.bool,
  rowPaddingBottom: PropTypes.bool,
  rowPaddingTop: PropTypes.bool,
  delay: PropTypes.number,
  items: PropTypes.object,
};

GalleryContent.defaultProps = {
  items: [
    { image: 'https://picsum.photos/1920/1080' },
    { image: 'https://picsum.photos/1920/1080' },
  ],
  hasAnimation: true,
  textAlign: 'center',
  full: true,
  rowPaddingBottom: true,
  rowPaddingTop: true,
};

export default GalleryContent;
