import React, { Component } from 'react';
import cx from 'classnames';
import Markdown from 'react-remarkable';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import AppStore from '../../store/AppStore';

import './styles.scss';

class TextContent extends Component {
  constructor() {
    super();

    const defaultState = {
      animateIn: false,
    };

    this.state = Object.assign(defaultState, AppStore.getConfig());
    this.fluxStateChange = this.fluxStateChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    AppStore.on('change', this.fluxStateChange);
  }

  componentWillUnmount() {
    AppStore.removeListener('change', this.fluxStateChange);
  }

  fluxStateChange() {
    this.setState(AppStore.getConfig());
  }

  render() {
    const {
      full,
      hasAnimation,
      rowPaddingTop,
      rowPaddingBottom,
      textAlign,
      delay,
      content,
    } = this.props;

    const { animateIn } = this.state;

    return (
      <Waypoint
        onEnter={() => {
          this.setState({
            animateIn: true,
          });
        }}
      >
        <div
          className={cx({
            vc_row: true,
            full,
            small: !full,
            'animate-in': animateIn,
            'has-animation': hasAnimation,
            row_padding_top: rowPaddingTop,
            row_padding_bottom: rowPaddingBottom,
            [`text-align-${textAlign}`]: textAlign !== null,
          })}
          data-delay={delay}
        >
          <div className="container">
            <Markdown options={{ breaks: true, html: true, linkify: true }}>
              {content}
            </Markdown>
          </div>
        </div>
      </Waypoint>
    );
  }
}

TextContent.propTypes = {
  content: PropTypes.string,
  hasAnimation: PropTypes.bool,
  textAlign: PropTypes.string,
  full: PropTypes.bool,
  rowPaddingBottom: PropTypes.bool,
  rowPaddingTop: PropTypes.bool,
  delay: PropTypes.number,
};

TextContent.defaultProps = {
  content: 'no content provided',
  hasAnimation: true,
  textAlign: 'center',
  full: true,
  rowPaddingBottom: true,
  rowPaddingTop: true,
};

export default TextContent;
